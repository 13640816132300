import React from "react";
import Hobby from "./Hobby";

function HobbyList({ hobbies }) {
  return (
    <>
      {hobbies.map((hobby) => (
          <Hobby key={hobby.id} hobby={hobby} />
      ))}
    </>
  );
}

export default HobbyList;

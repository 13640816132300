const scrollToMe = (elementRef) => {
  window.scrollTo({
    top: elementRef.current.offsetTop,
    behavior: "smooth",
  });
};

const AtTop = ({ homeRef, offset }) => {
  return (
    offset !== 0 && (
      <div className="backToTopContainer">
        <p id="backToTopLabel" onClick={() => scrollToMe(homeRef)}>
          [ back to top ]
        </p>
      </div>
    )
  );
};

function BackToTop({ homeRef, offset }) {
  return <AtTop homeRef={homeRef} offset={offset} />;
}

export default BackToTop;

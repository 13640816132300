import { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        'service_7tfxzjk',
        'contact_form',
        e.target,
        'u28kIsyjOIVXgtfX1'
        // process.env.REACT_APP_SERVICE_ID,
        // process.env.REACT_APP_TEMPLATE_ID,
        // e.target,
        // process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          setStateMessage("Message sent!");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage("Something went wrong, please try again later");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );

    // Clears the form after sending the email
    e.target.reset();
  };

  return (
    <form onSubmit={sendEmail} className="emailForm">
      {/* <label>Full Name</label> */}
      <input
        className="formOneLiner"
        type="text"
        name="user_name"
        placeholder="Full Name"
        required
      />

      {/* <label>Email</label> */}
      <input
        className="formOneLiner"
        type="email"
        name="user_email"
        placeholder="Email"
        required
      />

      {/* <label>Message</label> */}
      <textarea id="formTextArea" name="message" placeholder="Message" />

      <input
        type="submit"
        value="Send"
        disabled={isSubmitting}
        className="submitButton"
      />

      {stateMessage && <p>{stateMessage}</p>}
    </form>
  );
};
export default ContactForm;

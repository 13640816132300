import React from "react";

function HobbyExampleGrid({ hobby }) {

  const names = ["Opie", "Archie"];
  let i = 0;

  return (
    <div className="HobbyExampleGridContainer">
      <div className="HobbyExampleGridWrapper">
        <div className={hobby.gridClass}>
          {hobby.photos.map((photo) => (
            <div className="HobbyExampleGridCell" key={photo}>
              <div className="HobbyExampleGridCellImage" >
                <img className="HobbyPhoto" id={names[i++]} src={photo} alt="A Hobby Photo Example"/>
              </div>

              {/* <img className="jobLogo" src={jobs[numJob].image} /> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HobbyExampleGrid;

/*

              <div className="HobbyExampleGridCellContent">

                
                <span className="toolTipTextMeImage">
                  change me to an image
                </span>
                <div className="HobbyExampleGridCellContentImage"><p>{project}</p></div>

              </div>

              */

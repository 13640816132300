import React, { forwardRef, useRef, useState } from "react";
import HobbyList from "./HobbyList";

import Opie from "./Images/Opie.png";
import Archie from "./Images/Archie.png";

function Me(props, meRef) {
  const [hobbies, setHobbies] = useState([
    {
      id: 1,
      divID: "woodworking",
      type: "woodworking",
      projects: [
        "Coffee Tables",
        "Bedside Tables",
        "Frames",
        "Boxes",
        "Guitars",
        "Odds & Ends",
        "Cutting Boards",
      ],
      photos: [],
      blurb:
        "Much of my time outside of work is spent in my woodshop, building furniture. I mostly find inspirtation from Danish, Scandinavian, Mid-Century Modern, and American Craftsman styles. Photos to come!",
      gridClass: "HobbyExampleGrid_3x3",
      },
    {
      id: 2,
      divID: "metalworking",
      type: "metalworking",
      projects: ["Jewelry", "Sculptures"],
      photos: [],
      blurb: "I have recetly started down the rabbit hole of metalworking, learning how to craft fine and semi-fine jewelry pieces",
      gridClass: "HobbyExampleGrid_1x2",
    },
    {
      id: 3,
      divID: "design",
      type: "design",
      projects: [],
      photos: [],
      blurb: "Design, whether applied to products (tangible or not), interiors, exteriors, systems...whatever, has always intrigued me",
      gridClass: "HobbyExampleGrid",
    },
    {
      id: 4,
      divID: "animals",
      type: "animals",
      projects: ["Archie", "Opie"],
      photos: [Opie, Archie],
      blurb: "I unironically want to own a cow someday. But for now, here are my cats",
      gridClass: "HobbyExampleGrid_1x2",
    },
    {
      id: 5,
      divID: "ST",
      type: "world building & story telling",
      projects: [],
      photos: [],
      blurb: "Crafting unique worlds and stories for anything from books and comics to film and video games has always been a big passion of mine",
      gridClass: "HobbyExampleGrid",
    },
    {
      id: 6,
      divID: "music",
      type: "music",
      projects: [],
      photos: [],
      blurb: "I'm quite fond of sythesizers and enjoy noodling around with them via Ableton Live",
      gridClass: "HobbyExampleGrid",
    },
    {
      id: 7,
      divID: "climb",
      type: "climbing & fitness",
      projects: [],
      photos: [],
      blurb: "After returning to school, I had the time to get back into fitness and discovered a love for bouldering and rock climbing",
      gridClass: "HobbyExampleGrid",
    },
    {
      id: 8,
      divID: "travel",
      type: "travel",
      projects: [],
      photos: [],
      blurb: "Coming Soon!",
      gridClass: "HobbyExampleGrid",
    },
  ]);

  return (
    <div className="pageBlock">

    <div className="pages" id="mePage" ref={meRef}>
      <div className="meDiv">
        <div id="meDivHeader">
          <p>
            Outside of software, some easy topics to start a conversation with me
            about are...
          </p>
        </div>

      <div id="meDivInner">

        <div></div>
        <div className="meContent">
          <HobbyList hobbies={hobbies} />
        </div>
      </div>



      </div>
    </div>
    </div>
  );
}

export default forwardRef(Me);

import './App.css';

// pages to route to
import Home from './Home';
import Missing from './Missing';

// hooks
import { Route, Routes } from 'react-router-dom';

function App() {

  return (
    <div className="App" >
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/*" element={<Missing />}/>
      </Routes>
    </div>
  );
}

export default App;


import ProjectListNested from "./ProjectListNested";

function DropdownNested({isVisible, projectCategory, projects, handleVideo, numVideo}) {
  return (
    <div className="dropDownMenu"       
    style={{
        opacity: !isVisible ? "0" : "1",
        visibility: !isVisible ? "hidden" : "visible",
    }}>
        {isVisible ? (
          <ProjectListNested isVisible={isVisible} projectCategory={projectCategory} projects={projects} handleVideo={handleVideo} numVideo={numVideo}/>
    ) : null}
  </div>
  )
}

export default DropdownNested
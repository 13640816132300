import HomeDesktop from "./HomeDesktop.js";
import HomeMobile from "./HomeMobile.js";

import { useState, useEffect } from "react";

function Home({ offset }) {

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1005);
  const [isTablet, setTablet] = useState(window.innerWidth > 768);
  const [isMobile, setMobile] = useState(window.innerWidth > 800);

  const updateMedia = () => {
    // setDesktop(window.innerWidth > 1005);
    setDesktop(window.innerWidth > 590);
    setTablet(window.innerWidth > 768);
    setMobile(window.innerWidth < 590);
  };
  
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  return (
    isDesktop ? <HomeDesktop offset={offset}/> : <HomeMobile />
    // isDesktop ? <HomeDesktop offset={offset}/> : <HomeDesktop offset={offset}/> 
  );
}

export default Home;
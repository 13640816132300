function MobileDivider ({id, limit}) 
{
    return ( id != limit ?
        <div className="MobileDividerWrapper">
            <div className="MobileDivider">

            </div>
        </div>

        :

        <div></div>
    )
}

export default MobileDivider;
// import { useState } from 'react'

function ProjectItem({project, handleVideo}) {
  
  // const [opened, setOpened] = useState(true)
  
  // const toggleOpened = (opened) => {
  //   console.log(opened);
  //   setOpened(!opened);
  // }

  

  return (
    <>
      <label
        className='projectCategoryButton'
        id='projectSpecButtonText'
        // onClick={      () => { toggleOpened(opened); { opened ? handleVideo(project.id) : handleVideo(0)};  }}
        // onDoubleClick={() => { handleVideo(0)                                                               }}
        >
          {project.name}
      </label>
    </>
  )
}

export default ProjectItem
import ProjectItem from "./ProjectItem"

import { useState } from 'react'


function ProjectList({projectCategory, projects, handleVideo, numVideo}) {

  const filteredProjects = projects.filter((project) => project.category === projectCategory)
  
  const [opened, setOpened] = useState(true)
  
  const toggleOpened = (opened) => {
    console.log(opened);
    setOpened(!opened);
  }

  return (
    <ul id='projectUL'>
        {filteredProjects.map((project) => (
          <li key={project.id} className="project"  onClick={ () => { { numVideo==-1||numVideo+1!=project.id ? handleVideo(project.id) : handleVideo(0) };  }} >
              <ProjectItem key={project.id} project={project} handleVideo={handleVideo}/>
          </li>
        ))}
    </ul>       
  )
}

export default ProjectList
import ProjectList from "./ProjectList";

function Dropdown({isVisible, projectCategory, projects, handleVideo, numVideo}) {
    return (
      <div className="dropDownMenu"       
        style={{
            opacity: !isVisible ? "0" : "1",
            visibility: !isVisible ? "hidden" : "visible",
        }}>
            {isVisible ? (
              <ProjectList projectCategory={projectCategory} projects={projects} handleVideo={handleVideo} numVideo={numVideo}/>
        ) : null}
      </div>
    );
  }
  
  export default Dropdown;
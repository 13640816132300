import { forwardRef, useState } from "react";

import ReactPlayer from "react-player";
import DropdownItem from "./DropdownItem";
import DropdownItemNested from "./DropdownItemNested";
import MobileHeaders from "./MobileHeaders";

function ProjectPageMobile(props, projectRef) {
  const [projects, setProjects] = useState([
    {
      id: 1,
      category: "Game Engine Development",
      subcategory: "N/A",
      name: "Memory System",
      tech: ["C++, DirectX 11"],
      clicked: false,
      video: "",
      videoDescription: "Memory System Video Description",
      summary:
        "Developed a heap-based memory system with individual allocation management capability in order to support real time allocation and memory usage",
    },
    {
      id: 2,
      category: "Game Engine Development",
      subcategory: "N/A",
      name: "Math System",
      tech: ["C++"],
      clicked: false,
      video: "",
      videoDescription: "Math System Video Description",
      summary:
        "Programmed a stable and comprehensive library capable of vector, matrix and quaternion operations to be used in a custom graphics engine. Achieved roughly a 4x performance increase over standard library by using SIMD intrinsics",
    },
    {
      id: 3,
      category: "Game Engine Development",
      subcategory: "N/A",
      name: "File System",
      tech: ["C++, DirectX 11"],
      clicked: false,
      video: "",
      videoDescription: "File System Video Description",
      summary:
        "Used native OS functions to create a data storage mechanism (packing and retrieving system's program, 3D asset, and utility files) for improved real-time performance",
    },
    {
      id: 4,
      category: "Game Engine Development",
      subcategory: "N/A",
      name: "Object Hierarchy System",
      tech: ["C++, DirectX 11"],
      clicked: false,
      video: "videos/Object_Hierarchy_System.mp4",
      videoDescription: "Object Hierarchy System Video Description",
      summary:
        "Designed a tree system for implementing scene graphs and animation system in engine by propagating object transformations, lighting calculations, and various other properties from parent nodes to their children within any given animation",
    },
    {
      id: 5,
      category: "Game Engine Development",
      subcategory: "Graphics System",
      name: "Graphics System",
      tech: ["C++, DirectX 11, Google Protocol Buffers"],
      clicked: false,
      video: "",
      videoDescription: "Graphics System Video Description",
      summary: "Combined custom Math, Memory, File, Object, Animation & Skinning, Camera, and Collision systems to create a custom comprehensive graphics engine capable.",
    },
    {
      id: 6,
      category: "Game Engine Development",
      subcategory: "Graphics System",
      name: "FBX Converter",
      tech: ["C++, DirectX 11, Google Protocol Buffers"],
      clicked: false,
      video: "",
      videoDescription: "FBX Converter Video Description",
      summary:
        "Created an [.FBX, .GLB, .GLTF]->.customFormat file converter that extracts geometry, texture, and animation data from files and builds repacks it into a custom, runtime optimized file format. Protocall buffers were used as an intermediate file format for transferring data.",
    },
    {
      id: 7,
      category: "Game Engine Development",
      subcategory: "Graphics System",
      name: "Camera System",
      tech: ["C++, DirectX 11"],
      clicked: false,
      video: "",
      videoDescription: "Camera System Video Description",
      summary:
        "Developed a robust camera and controls system that supports multiple projection types (perspective, orthographic), multiple camera creations, camera management, view and projection matrix obtaining, frustum culling controls and more",
    },
    {
      id: 8,
      category: "Game Engine Development",
      subcategory: "Graphics System",
      name: "Animation & Skinning System",
      tech: ["C++, DirectX 11"],
      clicked: false,
      video: "",
      videoDescription: "Animation & Skinning System Video Description",
      summary:
        "Programmed system for loading and subsequently creating instances of and controlling 3D assets via object reserve pools.",
    },
    {
      id: 9,
      category: "Game Engine Development",
      subcategory: "Graphics System",
      name: "Shader Development",
      tech: ["C++, DirectX 11"],
      clicked: false,
      video: "",
      videoDescription: "Shader Development Video Description",
      summary:
        "Wrote custom Pixel and Vertex HLSL shaders for lighting animations with customizable parameters and custom Compute shaders for calculating animation keyframe interpolation to take advantage of GPU architecture",
    },
    {
      id: 10,
      category: "Game Engine Development",
      subcategory: "Graphics System",
      name: "Vertex Buffer Development",
      tech: ["C++, DirectX 11"],
      clicked: false,
      video: "",
      videoDescription: "Vertex Buffer Development Video Description",
      summary:
        "Created a custom D3D11 Vertex Buffer Manager for easy creation and control over DirectX vertex buffers with any combination of attributes",
    },
    {
      id: 11,
      category: "Game Engine Development",
      subcategory: "Graphics System",
      name: "Collision Detection System",
      tech: ["C++, DirectX 11"],
      clicked: false,
      video: "",
      videoDescription: "Collision Detection System Video Description",
      summary:
        "Implemented basic collision detection using mathematically generated vertex and index buffers for basic shapes such as Ritter’s Bounding Sphere",
    },
    {
      id: 12,
      category: "Software Performance Optimization",
      subcategory: "N/A",
      name: "Data Structures",
      tech: ["C++"],
      clicked: false,
      video: "",
      videoDescription: "Data Structures Video Description",
      summary: "Implemented various optimization techniques like load-in-place memory for faster data initializations, reworking standard data structures for optimal caching, etc.",
    },
    {
      id: 13,
      category: "Software Performance Optimization",
      subcategory: "N/A",
      name: "SIMD",
      tech: ["C++"],
      clicked: false,
      video: "",
      videoDescription: "SIMD Video Description",
      summary: "Enhanced standard Vector and Matrix math library by implementing SIMD intrinsics to take advantage of memory layout for an average of 5x performance boost",
    },
    {
      id: 14,
      category: "Software Performance Optimization",
      subcategory: "N/A",
      name: "Particle System",
      tech: ["C++"],
      clicked: false,
      video: "",
      videoDescription: "Particle System Video Description",
      summary: "Refactored and improved performance of a 200,000 particle system by 10x by implementing optimizations like RVO, invariant restructuring, and data type optimization",
    },
    {
      id: 15,
      category: "Audio Engine Development",
      subcategory: "N/A",
      name: "XAudio2 Engine",
      tech: ["C++, XAudio2"],
      clicked: false,
      video: "",
      videoDescription: "XAudio2 Engine Video Description",
      summary:
        "Used the XAudio2 API to to extract, process, and play raw audio within a custom game engine using 7 separate manager threads (Main thread, Resource loading thread, Audio Voice threads, Playback Control threads, Voice Coordination threads, Error Handling thread, Kill thread). Used system handles via XAudio2 callbacks to properly protect and monitor the memory of audio resources. Application supports complex playlist creation, volume controls, track blending, panning, time controls, and pitch controls",
    },
    {
      id: 16,
      category: "Audio Engine Development",
      subcategory: "N/A",
      name: "WIN32 Engine",
      tech: ["C++ WIN32 API"],
      clicked: false,
      video: "",
      videoDescription: "WIN32 Engine Video Description",
      summary: "Developed a real-time multithreaded audio engine with 6 manager threads and 20+ subordinate worker threads. Created a custom Win32 Handle System incorporating mutexes, futures, promises, async calls, and conditional variables to protect engine resources shared between threads. Developed a memory safe, inter-thread communication system by implementing the Command design pattern with independent thread queues to accept messages",
    },
    {
      id: 17,
      category: "Standalone Application Development",
      subcategory: "N/A",
      name: "Space Invaders",
      tech: ["C#"],
      clicked: false,
      video: "",
      videoDescription: "Space Invaders Video Description",
      summary: "Recreated the classic arcade game Space Invaders with modern architecture using 13 object oriented design patterns and 140+ classes that supports animating and moving textured sprites, processing keyboard input, detecting multiple types of collisions, a font system, responsive audio, levels/scenes, and more. Developed an early-out collision system to cut down on the number of collision checks each cycle. Created an object pool of game sprites to reduce new memory allocations.",
    },
    {
      id: 18,
      category: "Standalone Application Development - REMOVE",
      subcategory: "N/A",
      name: "Sketchpad",
      tech: ["Java"],
      clicked: false,
      video: "",
      videoDescription: "Sketchpad App Video Description",
      summary: "",
    },
    {
      id: 19,
      category: "Standalone Application Development",
      subcategory: "N/A",
      name: "Blockchain System",
      tech: ["Java, C"],
      clicked: false,
      video: "",
      videoDescription: "Blockchain Video Description",
      summary: "Created a secure, SHA-256 hashed, proof-of-work blockchain distributed over a number of servers complete with peer-to-peer, multicast and broadcast distribution systems for sharing the blockchain ledger",
    },
    {
      id: 20,
      category: "Standalone Application Development",
      subcategory: "N/A",
      name: "This Website",
      tech: ["JavaScript [React, React-Three-Fiber], HTML, CSS"],
      clicked: false,
      video: "",
      videoDescription: "This Website's Video Video Description",
      summary:
        "While I focused on real-time software engineering in school, I enjoy all sorts of development and decided to pick up React to build this site.",
    },
  ]);

  const [numVideo, setNumVideo] = useState(-1);

  const handleVideo = (id) => {
    // console.log("numVideo = " + numVideo);
    // console.log(`incoming project = ${id}`);
    // console.log(`new numVideo = ${id - 1}`);
    // console.log(" ");

    // console.log(id);

    setNumVideo(id - 1);
  };

  const closeVideo = () => {
    setNumVideo(-1);
  };

  return (
    <div className="pageBlock">

    <div className="pagesMobile" id="projectPage" ref={projectRef}>



      <MobileHeaders headerTitle={"Projects"}/>
      <div className="pagesMobileSpacing">
        <div id="spacerBoiMobile">


        <div className="projectDescriptionCardMobile">
            <div className="projectDiv" id="projectDemo">
              {numVideo <= -1 && (
                <div id="">
                  <p id="selectProjectNoticeMobile">
                    please select a project to examine
                  </p>
                </div>
              )}

              {numVideo > -1 && (
                <div id="projectVideoComp">

                  <div id="closeProject">
                    <div
                      id="closeProjectButton"
                      onClick={() => {
                        handleVideo(0);
                      }}
                    >
                      X
                    </div>
                  </div>

                  <div className="projectVideoCompVideoFlexWrapper"> 
                    <div className="projectVideoCompVideo">

                      
                      {projects[numVideo].video === "" ? 
                        <p id="VideoTmpMessage">Demo Videos Coming Soon!</p> :
                        <div className="player-wrapper">
                        <ReactPlayer
                          className="react-player"
                          width="100%"
                          height="100%"
                          url={projects[numVideo].video}
                          playing={true}
                          loop={true}
                        />
                      </div>
                      
                      
                    }





                    </div>
                  </div>

                  <div className="projectVideoCompCaptionsWrapper">
                    {/* <div className="projectVideoCompCaptions">
                      <p>
                        {numVideo > -1 && projects[numVideo].videoDescription}
                      </p>
                    </div> */}
                  </div>
                  <div className="projectDiv" id="projectVideoDetails">
                    <div className="projectVideoDescription">
                      <div className="projectVideoCompTitle">
                        <p>{numVideo > -1 && projects[numVideo].name}</p>
                      </div>
                      <div className="projectVideoCompSubTitle">
                        <p>{numVideo > -1 && projects[numVideo].tech}</p>
                      </div>
                      <div className="projectVideoCompSummaryMobile">
                        <p>{numVideo > -1 && projects[numVideo].summary}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>



          <div className="projectDiv" id="projectMenu">
            {/* <DropdownItem projectCategory="Game Engine Development" projects={projects} handleVideo={handleVideo} numVideo={numVideo}/> */}
            <DropdownItemNested
              projectCategory="Game Engine Development"
              projects={projects}
              handleVideo={handleVideo}
              numVideo={numVideo}
            />

            <DropdownItem
              projectCategory="Audio Engine Development"
              projects={projects}
              handleVideo={handleVideo}
              numVideo={numVideo}
            />
            <DropdownItem
              projectCategory="Software Performance Optimization"
              projects={projects}
              handleVideo={handleVideo}
              numVideo={numVideo}
            />
            <DropdownItem
              projectCategory="Standalone Application Development"
              projects={projects}
              handleVideo={handleVideo}
              numVideo={numVideo}
            />
          </div>

        
        </div>

      </div>
    </div>
    </div>
  );
}

export default forwardRef(ProjectPageMobile);

import React from "react";
import JobEntry from "./JobEntry";
import JobEntryLeft from "./JobEntryLeft";
import MobileDivider from "./MobileDivider";

const PageControl = ({ jobs, handleJobDesc, numJob }) => {
  return (
    <>
      {numJob === -1 && (
        <div>
          {
          
          jobs.map(
            
            job => {
              return (job.id % 2 == 0) ?
                <>
                <JobEntryLeft
                  key={job.id}
                  job={job}
                  company={job.company}
                  jobTitle={job.jobTitle}
                  concentration={job.concentration}
                  responsibilities={job.responsibilities}
                  image={job.image}
                  years={job.years}
                  handleJobDesc={handleJobDesc}
                  numJob={numJob}
                  />
                <MobileDivider id={job.id} limit={jobs.length}/>
                </>
                
                :
                
                <>
                <JobEntry
                key={job.id}
                job={job}
                company={job.company}
                jobTitle={job.jobTitle}
                concentration={job.concentration}
                responsibilities={job.responsibilities}
                image={job.image}
                years={job.years}
                handleJobDesc={handleJobDesc}
                numJob={numJob}
                />
                <MobileDivider id={job.id} limit={jobs.length}/>
                </>
                
              }
              
              
        )
          
          }
        </div>
      )}

      {numJob > -1 && (
        <div className="jobDescriptionCard">
          <div className="workplaceWrapper">
            <div className="workPlaceFocus">
              <div className="workPlaceFocusClickable" onClick={() => handleJobDesc(0)}>
                <div className="iconHolder">
                  <div className="iconSize">
                    <div className="icon">
                      <img className="jobLogo" src={jobs[numJob].image} alt="the currently selected job's logo"/>
                    </div>
                  </div>
                </div>

                <div className="jobTitleLeftCard">
                  <div className="jobCompany">{jobs[numJob].company}</div>
                  <div className="jobTitle">{jobs[numJob].jobTitle}</div>
                  <div className="jobYears">{jobs[numJob].years}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="jobBlurbMobile">{jobs[numJob].blurb}</div>
          <div className="jobDescriptionContentMobile">
            <ul key={jobs[numJob].id}>
              {jobs[numJob].responsibilities.map((responsibility) => (
                <li key={responsibility}>{responsibility}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

function JobListMobile({ jobs, handleJobDesc, numJob }) {
  return (
    <>
      <PageControl jobs={jobs} handleJobDesc={handleJobDesc} numJob={numJob} />
    </>
  );
}

export default JobListMobile;

import { forwardRef } from "react";
import BenjaminCode_CV_Long from "./docs/Resume_Long_2024.pdf";
import BenjaminCode_CV_Short from "./docs/Resume_Short_2024.pdf";

function Landing(props, homeRef) {
  return (
    <div className="pages" id="landing" ref={homeRef}>
      <div className="landingPageContent">
        <p id="welcome">Benjamin Code</p>
        <div className="downloadButtonsContainer">
          <div className="downloadButtonsCenterer">
            <div className="downloadCVButton">
              <div className="downloadCVButtonContent">
                <a
                  href={BenjaminCode_CV_Long}
                  download="BenjaminCode_CV_Long"
                  target="_blank"
                >
                  <button
                    type="download"
                    className="myButtons"
                    aria-label="download cv"
                    download="BenjaminCode_CV_Long"
                    id="downloadButton"
                  >
                    <p id="downloadCVButtonText">download full cv</p>
                  </button>
                </a>
              </div>
            </div>
            <div className="downloadCVButton">
              <div className="downloadCVButtonContent">
                <a
                  href={BenjaminCode_CV_Short}
                  download="BenjaminCode_CV_Short"
                  target="_blank"
                >
                  <button
                    type="download"
                    className="myButtons"
                    aria-label="download cv"
                    download="BenjaminCode_CV_Short"
                    id="downloadButton"
                  >
                    <p id="downloadCVButtonText">download single page resume</p>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Landing);

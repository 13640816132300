import DropdownNested from "./DropdownNested";
import DropdownButton from "./DropdownButton";
import { useState } from "react"

function DropdownItemNested({projectCategory, projects, handleVideo, numVideo}) {

    const [isVisible, setIsVisible] = useState(false);
  
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    return (
        <div className="dropDownWrapper">
            <DropdownButton onClick={toggleVisibility} projectCategory={projectCategory}/>
            <DropdownNested isVisible={isVisible} projectCategory={projectCategory} projects={projects} handleVideo={handleVideo} numVideo={numVideo}/>
        </div>
    )
}

export default DropdownItemNested
function MobileHeaders({headerTitle}) 
{
    return(    
        <div className="workParagraphsMobile">
        <div id="workExpHeader">
        <p>{headerTitle}</p>
        </div>
        </div> 
    )
}

export default MobileHeaders
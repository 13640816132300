import React from "react";
import { useRef,useState } from "react";

import LandingMobile from "./LandingMobile";
import WorkExpMobile from "./WorkExpMobile";
import ProjectPageMobile from "./ProjectPageMobile";
import MeMobile from "./MeMobile";
import ContactMobile from "./ContactMobile";

function HomeMobile() {
  const homeRef = useRef(null);
  const experienceRef = useRef(null);
  const projectRef = useRef(null);
  const meRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <div className="masterBackground">

    <div id="mobileOops">

      <LandingMobile ref={homeRef} />
      <WorkExpMobile ref={experienceRef} />
      <ProjectPageMobile ref={projectRef} />
      <MeMobile ref={meRef} />
      <ContactMobile ref={contactRef} />
    </div >
    </div>
  ) 
}

export default HomeMobile;

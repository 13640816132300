import React, { useState, useEffect, forwardRef } from "react";
import DepaulLogo from "./Images/depaulLogo_Grey.png";
import NBCU from "./Images/nbcu.png";
import NBCPeacock from "./Images/nbcLogo_Grey.png";
import PeacockLogo from "./Images/peacockLogo_AllGrey.png";
import DoordashLogo from "./Images/doorDashLogo_Grey.png";
import SnapchatLogo from "./Images/snapLogo_Grey.png";
import JobList from "./JobList";

function WorkExp(props, experienceRef) {
  const [skills, setSkills] = useState([
    {
      id: 1,
      skill: "languages",
      list: ["C++, C, C#, Java", "Python, JavaScript", "SQL"],
    },
    {
      id: 2,
      skill: "development skills",
      list: [
        "Version Control [Git, Perforce] usage",
        "Modern IDEs [Visual Studio, VS Code] and Command Line / Terminal usage",
        "Familiarity with multiple operating systems [Windows, MacOS, Linux]",
        "Structured Debugging",
        "Pair Programming",
        "UML Diagramming & Visual Communication",
      ],
    },
    {
      id: 3,
      skill: "technical areas",
      list: [
        "Real-Time Software Architecture & Design",
        "Concurrency & Multithreading",
        "Optimization & Performance",
        "Custom Library Creation & Integration",
      ],
    },
    {
      id: 4,
      skill: "soft skills",
      list: [
        "Excellent communication",
        "Understanding of & adaptable to work prioritization",
        "Team oriented & collaborative",
        "Conflict resolution",
        "Time Management",
        "Empathetic problem solving",
      ],
    },
  ]);

  const [jobs, setJobs] = useState([
    {
      id: 1,
      company: "DePaul University",
      jobTitle: "Bachelor of Communications",
      concentration: "N/A",
      responsibilities: [
        "Focused on communication and media studies",
        "Learned effective diplomatic, conflict resolution, and collaboration skills",
        "Developed the ability to clearly and efficiently disseminate essential information both verbally and written",
        "Honed public speaking abilities and interpersonal skills",
        "Worked as an Assisstant at Lowis & Gellen, LLP",
        "Interned at Big Shoulders Digial Video Productions",
        "Worked as a Food Runner for Green River's 2016 Michelin Star winning team",
      ],
      blurb:
        "Completed a four year Bachelors of Communication Degree, graduating with distinction.",
      image: DepaulLogo,
      years: "2013 - 2017",
    },
    {
      id: 2,
      company: "NBCUniversal",
      jobTitle: "Leadership Development Program",
      concentration: "N/A",
      responsibilities: [
        "Held roles in Universal Studios Hollywood Operations & Communications Department, NBC Alternative Creative Programming & Development, Universal Pictures Brand Marketing, and NBCU's joint venture with Snapchat, where I was hired on after the rotational program's end",
        "Coordinated everything from calendars to film and digital assets for films like Jurassic World, The Grinch, and Despicable Me",
        "Created pitch decks and sales materials for multi-million dollar projects",
        "Tracked and reported on key project data",
        "Filmed, edited, and wrote copy for marketing materials",
        "Managed advertising campaign activations and events",
      ],
      blurb:
        "Selected to participate in NBCUniversal's West Coast Page Program. Completed 4 rotations across various NBCU business entities over the course of one year to develop leadership skills and a comprehensive overview of the company.",
      image: NBCPeacock,
      years: "2017 - 2018",
    },
    {
      id: 3,
      company: "Snapchat & NBCU's Indigo",
      jobTitle: "Project Coordinator",
      concentration: "N/A",
      responsibilities: [
        "Assisted in development of original programming by evaluating incoming pitches and shaping subsequent projects both creatively & logistically",
        "Created pitch decks & assisted in selling short form television shows",
        "Performed research and managed special projects for the partnership management, business development, research, and legal teams",
        "Filmed and edited videos, photographs, and other types of media for both creative and marketing purposes",
        "Acted as main point of contact for technical & design needs of vendors and contractors",
        "Oversaw company’s creative assets and marketing materials",
        "Developed comprehensive operations & best practice guides for vendors",
        "Interviewed candidates and assisted in hiring and training new team members",
      ],
      blurb:
        "Internally recruited from NBCU to assist in launching NBCUniversal & Snapchat’s Joint Venture, Indigo, which led Snapchat’s “Snap Originals” short form TV initiative.",
      image: SnapchatLogo,
      years: "2018-2019",
    },
    {
      id: 4,
      company: "NBCUniversal's Peacock",
      jobTitle: "Project Coordinator",
      concentration: "N/A",
      responsibilities: [
        "Continued previous responsibilities from Indigo",
        "Completed research, data analysis, and special projects for platform marketing, content, and design",
        "Provided Legal assistance & support regarding the divestment from Snap, inc.",
        "Created decks and wrote presentations for NBCU Digital Enterprises senior leadership",
      ],
      blurb:
        "Returned solely to NBCUniversal to assist in the launching of their new digital streaming service, Peacock, following NBCU's divestment from Snap, inc.",
      image: PeacockLogo,
      years: "2019 - 2020",
    },
    {
      id: 5,
      company: "DoorDash's Trigger Group",
      jobTitle: "Account Executive",
      concentration: "N/A",
      responsibilities: [
        "Conducted research for business development and customer growth",
        "Pitched products and services to potential customers while managing existing relationships",
        "Worked with Data team to find and optimize sales trends",
        "Create informational decks to assist in training new team members",
      ],
      blurb:
        "Joined Trigger Group as part of their first US team to grow their business in North America following their investment from DoorDash.",
      image: DoordashLogo,
      years: "2020-2022",
    },
    {
      id: 6,
      company: "DePaul University",
      jobTitle: "Master of Science, Computer Science",
      concentration: "Real-time Systems & Software Development",
      responsibilities: [
        "Took advanced coursework in Data Structures, Algorithms, and Real-Time Software & Systems Development",
        "Primarily worked in C++, with additional work in C, C#, Java, Python, and JavaScript",
        "Studied a plethora of optimization techniques such as SIMD operations, load-in-place data structures, and custom heap-based memory systems",
        "Frequently utilized multithreading in C++ for complex application development like 3D graphics engines, audio engines, and custom utility systems (file/asset sharing, etc)",
        "Worked directly with low level systems and components like GPUs to develop compute shaders in order to enhance performance",
        "Wrote and utilized custom libraries in C++",
        "Wrote custom Pixel an Vertex shaders in DirectX 10",
        "Contributed to Azul, DePaul's open source game engine project",
      ],
      blurb:
        "Returned to DePaul to take all undergrad level requirements for Computer Science and continued on to complete my Masters degree with a focus on Real-Time Systems and Software Development",
      image: DepaulLogo,
      years: "2021 - 2024",
    },
  ]);

  const [numJob, setNumJob] = useState(-1);
  const [selectedSkill, setSelectedSkill] = useState(0);

  const handleJobDesc = (id) => {
    // console.log(id);
    setNumJob(id - 1);
  };

  const handleSkillSelection = (id) => {
    setSelectedSkill(id - 1);
  };

  return (
    <div className="pageBlock">
    <div className="pages" ref={experienceRef}>
      <div className="expMF">
        <div className="container">
          <div className="workStory">
            <div id="storyAdjust">
              <div className="workParagraphs">
                <div id="workExpHeader">
                  <p>Career Path Overview</p>
                </div>

                <p>
                  After working in the entertainment industry in a variety of
                  project management roles that ultimately led to the
                  opportunity to contribute to the launch of Snapchat's short
                  form content studio, Indigo, and later NBCUniversal's
                  streaming service, Peacock, I was exposed to enough technical
                  work to I realized I was just as interested in how these
                  platforms ran as I was the shows I was able to put on them.
                </p>
                <br />
                <p>
                  In 2020, I made the decision to pursue a degree in Computer
                  Science. After finishing what would have been my undergraduate
                  CS coursework while working as an Account Executive at DoorDash's
                  Trigger Group, I began a working towards my Master of Science
                  in Computer Science with a concentration in real time systems
                  and software development.
                </p>
                <br />
                <p>
                  Today, I am a well rounded professional and capable software
                  engineer with a passion for writing highly optimized code.
                  I am excited to continue building high performance systems and
                  contributing to large scale projects.
                </p>
              </div>
            </div>

            <div className="storySkills">
              <div className="storySkillsTitleList">
                {skills.map((skill) => (
                  <div id="storySkillsListWrapper" key={skill.id}>
                    <div
                      className="storySkillTitleListEntryClickable"
                      id={skill.id}
                      onClick={() => handleSkillSelection(skill.id)}
                    >
                      <div className="storySkillTitleListEntry">
                        {skill.skill}
                      </div>
                    </div>

                    <div id="space">{skill.id < skills.length && " "}</div>
                    <div className="storySkillTitleListEntryDivider">
                      {skill.id < skills.length ? "/" : ""}
                    </div>
                    <div id="space">{skill.id < skills.length && " "}</div>
                  </div>
                ))}
              </div>
              <div className="storySkillsListContainer">
                <ul className="storySkillsList">
                  {selectedSkill >= 0 &&
                    skills[selectedSkill].list.map((listEntry) => (
                      <li className="storySkillListEntry" key={listEntry}>
                        {listEntry}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="workSpecificsWrapper">
            <div className="workSpecifics">
              <JobList
                jobs={jobs}
                handleJobDesc={handleJobDesc}
                numJob={numJob}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>


  );
}

export default forwardRef(WorkExp);

import Landing from "./Landing";
import WorkExp from "./WorkExp";
import ProjectPage from "./ProjectPage";
import Me from "./Me";
import Contact from "./Contact";
import Nav from "./Nav";
import BackToTop from "./BackToTop.js";

import { useRef,useState } from "react";

function HomeDesktop({offset}) {
  const homeRef = useRef(null);
  const experienceRef = useRef(null);
  const projectRef = useRef(null);
  const meRef = useRef(null);
  const contactRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  // const toggleVisibility = () => {
  //   setIsVisible(!isVisible);
  // };

  return (
    <div className="masterBackground">
      <Nav
        homeRef={homeRef}
        experienceRef={experienceRef}
        projectRef={projectRef}
        meRef={meRef}
        contactRef={contactRef}
      />
      <main>
        <Landing ref={homeRef} />
        <WorkExp ref={experienceRef} />
        <ProjectPage ref={projectRef} />
        <Me ref={meRef} />
        <Contact ref={contactRef} />
      </main>
      <BackToTop homeRef={homeRef} isVisible={isVisible} offset={offset} />
    </div>
  );
}

export default HomeDesktop;

const DropdownButton = ({onClick, projectCategory}) => {
  return (
    <button className="projectButton" onClick={onClick}>
      <label id='projectButtonText'>
        {projectCategory}
      </label>
    </button>
  );
}

export default DropdownButton;
import ProjectItem from "./ProjectItem"
import Dropdown from "./Dropdown"

import { useState } from 'react'


function ProjectListNested({isVisible, projectCategory, projects, handleVideo, numVideo}) {

  const filteredProjects = projects.filter((project) => project.category === projectCategory)
  
  const [opened, setOpened] = useState(true)
  
  const toggleOpened = (opened) => {
    console.log(opened);
    setOpened(!opened);
  }

  const MixedList = ({filteredProjects}) => {

         {return filteredProjects.map((project) => (     

          <li key={project.id} className="project"  onClick={ () => { { numVideo==-1||numVideo+1!=project.id ? handleVideo(project.id) : handleVideo(0) };  }} >
              <ProjectItem key={project.id} project={project} handleVideo={handleVideo}/>
          </li>
        ))}

  }


  return (
    <ul id='projectUL'>

        <MixedList filteredProjects={filteredProjects}/>

        {/* {filteredProjects.map((project) => (     

          <li key={project.id} className="project"  onClick={ () => { { numVideo==-1||numVideo+1!=project.id ? handleVideo(project.id) : handleVideo(0) };  }} >
              <ProjectItem key={project.id} project={project} handleVideo={handleVideo}/>
          </li>
        ))} */}
    </ul>       
  )
}

export default ProjectListNested
function Nav({homeRef, experienceRef, projectRef, meRef, contactRef}) {
  
  const scrollToMe = (elementRef) => {
    window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: "smooth",
    });
};

  return (
      <nav>
        <a className="title" onClick={() => scrollToMe(homeRef)}>
          Benjamin Code
        </a>
        <ul>
          <li>
            <a  onClick={() => scrollToMe(experienceRef)}>
              experience
            </a>
          </li>
          <li>
            <a  onClick={() => scrollToMe(projectRef)}>
              projects
            </a>
          </li>
          <li>
            <a onClick={() => scrollToMe(meRef)}>
              about me
            </a>
          </li>
          <li>
            <a onClick={() => scrollToMe(contactRef)}>
              contact
            </a>
          </li>
        </ul>
      </nav>
  )
}

export default Nav
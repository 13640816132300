/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 pothos.glb -T s 
Files: pothos.glb [3.89MB] > C:\Users\Ben\Portfolio\ben_code_portfolio\public\models\pothos-transformed.glb [358.71KB] (91%)
Author: AllQuad (https://sketchfab.com/AllQuad)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/free-pothos-potted-plant-money-plant-e9832f38484f4f85b3f9081b51fa3799
Title: [FREE] Pothos Potted Plant - Money Plant
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Pothos({scale, position}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Pothos/pothos-transformed.glb')
  return (
    <group ref={group} dispose={null} position={position} scale={scale}>
      <mesh geometry={nodes.POT_WOOD_LEGS_WOODEN_LEGS_POT_0.geometry} material={materials.WOODEN_LEGS_POT} rotation={[-Math.PI / 2, 0, 0]}/>
      <mesh geometry={nodes.POTHOS_POTHOS_Mat_0.geometry} material={materials.POTHOS_Mat} position={[0.005, 0.49, 0.029]} scale={0.52} />
    </group>
  )
}

useGLTF.preload('/models/Pothos/pothos-transformed.glb')

import React from "react";
import HobbyItem from "./HobbyComponents/HobbyItem";

function Hobby({hobby}) {
  return (
        <HobbyItem hobby={hobby}/>
  );
}

export default Hobby;

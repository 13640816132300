import { useState } from "react";
import HobbyExampleGrid from "../HobbyExampleGrid";

const HobbyLabel = ({ hobby }) => {
  return (
    <div className="HobbyCard">
      <div className="HobbyCardTitle">{hobby.type}</div>
    </div>
  );
};

const HobbyHovered = ({ hobby }) => {
  return (
    <div className="HobbyHovered">
      <div className="HobbyHoveredTitle">
        <p>{hobby.type}</p>
      </div>
      <div className="HobbyBlurb">
        <p>{hobby.blurb}</p>
      </div>

      {hobby.projects.length > 0 ? (
        <div className="HobbyExamples">
          <HobbyExampleGrid hobby={hobby} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

function HobbyItem({ hobby }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="hobbyBox"
      id={hobby.divID}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {isHovered ? (
        <HobbyHovered hobby={hobby} />
      ) : (
        // <HobbyHovered hobby={hobby} />
        <HobbyLabel hobby={hobby} />
      )}
    </div>
  );
}

export default HobbyItem;

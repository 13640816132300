
function JobEntry({
  job,
  company,
  jobTitle,
  concentration,
  responsibilites,
  image,
  years,
  handleJobDesc,
}) {
  return (
    <div className="workplaceWrapper">
      <div className="workPlace">
        <div
          className="workPlaceClickable"
          onClick={() => handleJobDesc(job.id)}
        >
          <div className="jobHolder">
            <div className="jobCompany">{company}</div>
            <div className="jobTitle">{jobTitle}</div>
            <div className="jobYears">{years}</div>
          </div>
          <div className="iconHolder">
            <div className="iconSize">
              <div className="icon">
                <img className="jobLogo" src={image} alt="one of my job logos"/>
              </div>
            </div>
          </div>
        <div className="toolTipText" id="workPlaceClickableToolTip">
          click me
        </div>
        </div>
      </div>

      {/* <span className="toolTipText">click to copy</span> */}
    </div>
  );
}

export default JobEntry;

import Dropdown from "./Dropdown";
import DropdownButton from "./DropdownButton";
import { useState } from "react"

function DropdownItem({projectCategory, projects, handleVideo, numVideo}) {

    const [isVisible, setIsVisible] = useState(false);
  
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    return (
        <div className="dropDownWrapper">
            <DropdownButton onClick={toggleVisibility} projectCategory={projectCategory}/>
            <Dropdown isVisible={isVisible} projectCategory={projectCategory} projects={projects} handleVideo={handleVideo} numVideo={numVideo}/>
        </div>
    );
  }
  
  export default DropdownItem;